@import '../../../../variables.css';

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: 68px 0;
    z-index: 2;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

.content .leftSide {
    width: 30%;
    padding: 0 20px;
    text-align: right;
}

.content .leftSide > img {
    max-width: 300px;
    min-width: 150px;
    width: 100%;
}

.content .rightSide {
    width: 70%;
    padding: 0 20px;
}

.content .rightSide > img {
    display: block;
    margin-bottom: 30px;
    max-height: 280px;
}

.content .rightSide h2 {
    text-align: left;
    margin-bottom: 32px;
    font-size: var(--ark-48-font-size);
}

.content .rightSide .text {
    margin-bottom: 25px;
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-30-line-height);
    max-width: 90%;
    text-align: justify;
    font-weight: var(--ark-font-weight-regular);
}

.textBox {
    width: 60%;
    z-index: 2;
}

.mobilePanels {
    display: none;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
}

@media (max-width: var(--ark-medium-desktop-figma-1366)) {
    .content {
        /*padding: 68px 0;*/
    }

    .content .rightSide h2 {
        text-align: left;
        font-size: var(--ark-48-font-size);
        line-height: var(--ark-56-line-height);
    }
}

@media (max-width: var(--ark-small-desktop-figma-1280)) {
    .content .rightSide h2 {
        margin-bottom: 20px;
        font-size: var(--ark-40-font-size);
    }

    .content .rightSide .text {
        margin-bottom: 20px;
        font-size: var(--ark-18-font-size);
        max-width: 95%;
    }
}

@media (max-width: var(--ark-medium-mobile-figma-768)) {
    .content {
        padding: 53px 0;
    }

    .content .rightSide > img {
        margin-bottom: 20px;
        max-height: 60px;
    }

    .content .rightSide h2 {
        margin-bottom: 20px;
        font-size: var(--ark-28-font-size);
    }

    .content .rightSide .text {
        margin-bottom: 20px;
        font-size: var(--ark-16-font-size);
        max-width: 100%;
    }
    .noPadding {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .container {
        padding-bottom: 32px;
    }

    .content {
        width: 100%;
        padding-top: 32px;
        padding-bottom: 32px;
        justify-content: center;
    }

    /*.noPadding {*/
    /*    padding-top: 0 !important;*/
    /*    padding-bottom: 0 !important;*/
    /*}*/

    .content .leftSide {
        width: 0%;
        padding: 0;
    }

    .content .rightSide {
        width: 100%;
        padding: 32px;
    }

    .content .rightSide h2 {
        line-height: var(--ark-31-line-height);
    }

    .content .leftSide > img {
        display: none;
    }

    .mobilePanels {
        display: flex;
        padding: 32px 16px;
    }

    .textBox {
        width: 100%;
    }

    .content .rightSide .text {
        width: 100%;
        font-size: var(--ark-16-font-size);
        text-align: left;
    }
}
